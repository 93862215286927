<template>
  <v-card class="auto-grade-help pa-3">
    <v-card-title class="headline">Auto-grading explained</v-card-title>

    <v-card-text>
      <p>
        With auto grading enabled, the system live-updates the grade and the grade-stability indicator based on the
        grade votes by community (including setters). The stability indicator is updated according to the number 'Votes
        to mark stable grade' as set in the gym settings tab: currently {{ stableVotes }} in your case.
      </p>

      <p>The stability indicator takes three values:</p>
      <ul class="stability-states">
        <li>
          <tl-grade-circle :color="'#eeeeee'" :grade="6.0" :grade-stability="0" />
          <strong>Unstable</strong>: 0 to 50% of the required number of votes.
        </li>
        <li>
          <tl-grade-circle :color="'#eeeeee'" :grade="6.0" :grade-stability="0.5" />
          <strong>Probably stable</strong>: 50 to 99% of the required number of votes.
        </li>
        <li>
          <tl-grade-circle :color="'#eeeeee'" :grade="6.0" :grade-stability="1" />
          <strong>Stable</strong>: 100% or more than the required number of votes.
        </li>
      </ul>

      <h3 class="title mt-3">Example 1</h3>
      <p>
        If your community graph would look like below, the grade will be
        <tl-grade-circle :color="'#eeeeee'" :grade="7.33" :grade-stability="1" />. The total number of votes is
        {{ example1TotalVotes }}, so the grade is marked as 'stable'.
      </p>
      <div class="example-graph">
        <tl-grade-votes-graph :votes="example1GraphGrades" />
      </div>

      <h3 class="title mt-3">Example 2</h3>
      <p>
        If your community graph would look like below, the grade will be
        <tl-grade-circle :color="'#eeeeee'" :grade="7.0" :grade-stability="0.5" />. The total number of votes is
        {{ example2TotalVotes }}. This is between 50 and 100% of the required {{ stableVotes }} votes, so the grade is
        marked as 'probably stable'.
      </p>
      <div class="example graph">
        <tl-grade-votes-graph :votes="example2GraphGrades" />
      </div>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn text color="primary" @click="close">Got it!</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import tlGradeCircle from '@/components/gym/climbs/tl-grade-circle'
import tlGradeVotesGraph from '@/components/gym/climbs/shared/tl-grade-votes-graph'

export default {
  components: {
    tlGradeCircle,
    tlGradeVotesGraph,
  },
  data: () => ({
    example1GraphGrades: [
      { grade: 6.83, count: 1 },
      { grade: 7.0, count: 2 },
      { grade: 7.17, count: 5 },
      { grade: 7.33, count: 13 },
      { grade: 7.5, count: 10 },
      { grade: 7.66, count: 2 },
      { grade: 7.83, count: 1 },
    ],
    example1TotalVotes: 34,
  }),
  computed: {
    ...mapState(['gym', 'climbType']),
    stableVotes() {
      return (this.gym && this.gym.auto_grade_stable_votes) || 7
    },
    grade1Votes() {
      if (this.stableVotes < 5) return 0
      let votes = Math.round(this.stableVotes / 6)
      return votes == this.grade2Votes ? Math.max(this.grade2Votes - 1, 0) : votes
    },
    grade2Votes() {
      let factor = this.stableVotes < 5 ? 3 / 5 : 1 / 3
      return Math.round(this.stableVotes * factor)
    },
    grade3Votes() {
      return Math.round(this.stableVotes / 5)
    },
    example2GraphGrades() {
      return [
        { grade: 6.83, count: this.grade1Votes },
        { grade: 7.0, count: this.grade2Votes },
        { grade: 7.17, count: this.grade3Votes },
      ]
    },
    example2TotalVotes() {
      return this.grade1Votes + this.grade2Votes + this.grade3Votes
    },
  },
  methods: {
    ...mapActions('dialog', ['close']),
  },
}
</script>

<style lang="sass">
.auto-grade-help
  ul.stability-states
    list-style: none
    padding: 0
    margin: 0

  .tl-grade-circle
    display: inline-block
    vertical-align: middle
    width: 35px
    height: 35px

  li > .tl-grade-circle
    margin: 10px 15px 10px 5px

  p > .tl-grade-circle
    margin: 0 5px
</style>
